<template>
  <div class="section-main__content">
    <inline-svg :src="require('../../assets/icon/svg/plug-fail.svg')" />
    <div class="section-main__title">
      <h1 class="fail">連線逾時，充電槍連結失敗</h1>
    </div>
    <p>請插上充電槍重新連線</p>
  </div>
</template>
<script>
export default {
  name: "PlugFailedMessage.vue",
};
</script>
