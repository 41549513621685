<template>
  <section class="section-main">
    <div class="section-main__content" v-if="!isFailed && !isProcessing">
      <inline-svg :src="require('../../assets/icon/svg/plugHint.svg')" />
      <div class="section-main__title"><h1>請將充電槍插入</h1></div>
      <p>連接充電槍與電動車充電插座</p>
    </div>
    <IsPreparingMessage v-if="isProcessing" />
    <PlugFailedMessage v-if="isFailed" />
    <div class="section-main__action" v-if="!isFailed && !isProcessing">
      <button
        type="button"
        class="button is-fullwidth is-fill is-rounded"
        @click="handleClick()"
        :disabled="isProcessing"
      >
        下一步
      </button>
    </div>
    <div class="section-main__action" v-else>
      <button
        type="button"
        class="button is-fullwidth is-fill is-rounded"
        @click="handleClick()"
        :disabled="isProcessing"
      >
        連結充電槍
      </button>
      <button
        type="button"
        class="button is-fullwidth is-rounded mt-3"
        @click="handleClickBack()"
        :disabled="isProcessing"
      >
        回到充電槍配對
      </button>
    </div>
  </section>
</template>
<script>
import connectorAPI from "../../apis/connector";
import IsPreparingMessage from "./IsPreparingMessage.vue";
import PlugFailedMessage from "./PlugFailedMessage.vue";
export default {
  name: "PlugMessage.vue",
  data() {
    return {
      isFailed: false,
      isProcessing: false,
      connectorStatus: {
        Available: 1,
        Unavailable: 2,
        Preparing: 3,
        Charging: 4,
        SuspendedEVSE: 5,
        SuspendedEV: 6,
        Finishing: 7,
        Faulted: 8,
        Remove: 9,
        Reserved: 10,
      },
    };
  },
  components: { IsPreparingMessage, PlugFailedMessage },
  methods: {
    handleClick() {
      this.isProcessing = true;
      this.isFailed = false;
      const { connectorId } = this.$route.params;
      this.checkConnectorState(connectorId);
    },
    handleClickBack() {
      this.isProcessing = true;
      this.isFailed = false;
      this.$router.replace({
        name: "ConnectorSearch",
      });
    },
    async checkConnectorState(connectorid) {
      try {
        const { data, status } = await connectorAPI.connector.getStatus({
          connectorid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        if (data.status === this.connectorStatus.Preparing) {
          const { stationId } = this.$route.params;
          this.$router.replace({
            name: "Payment",
            params: { connectorId: connectorid, stationId: stationId },
          });
        } else {
          this.isProcessing = false;
          this.isFailed = true;
        }
      } catch (error) {
        this.isProcessing = false;
        const { stationId, connectorId } = this.$route.params;
        this.$router.replace({
          name: "ErrorHint",
          params: {
            stationId: stationId,
            connectorId: connectorId,
          },
        });
        console.log(error);
      }
    },
  },
};
</script>
